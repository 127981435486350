import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Button,
  Paper,
  Card,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  TopbarInside: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "9px",
    height: "100px",
    paddingLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px",
    },   
  },
  "& .paperBox": {
    width: "100%",
    height: "100vh",
    padding: "0px !important",
    // [theme.breakpoints.down("xs")]: {
    //   padding: "20px !important",
    // },
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",

    //backgroundImage: `url('images/login.png')`, // Replace with your image path
    backgroundSize: "cover", // Adjust as needed
    backgroundRepeat: "no-repeat", // Adjust as needed
    minHeight: "100vh",
  },
  rightBox: {
    //width: "50%",
    color: "white",
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding:"0px 20px"
    },
  },
  leftBox: {
   // width: "50%",
    background: "#FFFFFF",

    padding: "26px",

   // height: "100vh",
    "& h1": {
      fontSize: "41px",
      lineHeight: "48.18px",
      color: "#FFFFFF",
      [theme.breakpoints.down("md")]:{
        fontSize:"35px",
        lineHeight:"36px"
      }
    },
    "& .card": {
      borderRadius: "16px",
      boxShadow: "-1.85px -1.14px 3px 0px #FAFAFA38 inset",
      background: "#FFFFFFF5",
      padding: "10px",
    },
    "& .cardContent": {
      //  padding:"20px",
      "@media (max-width:1300px)": {
        padding: "0px",
      },
      [theme.breakpoints.down("md")]: {
        "& h4": {
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "20px",
        },
      },
    },
    "& .MuiPaper-elevation1": {
      padding: "00px !important",
      borderRadius: "16px",
    },
    "& .imageContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "37px",
      height: "37px",
      border: "2px solid #00A468",
      borderRadius: "10px",
    },
    "& h4": {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  OrangeSection: {
    display: "flex",
    flexDirection: "column",
   
    height: "100%",
    background: "#00A468",
    borderRadius: "20px",
    padding: "20px",
   gap: "30px",
   [theme.breakpoints.down("md")]:{
    gap:"35px"
   }
   
   
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    height: "100%",
  },
  children: {
    padding:"5%",
    alignItems:"center",
    width: "100%",
    flex: 1,
    //padding: "50px 100px",

    overflowY: "auto", // Enables vertical scrolling
    display:"flex",
     //justifyContent:"center",
    //  marginTop:"95px",
     marginLeft:"160px",
     marginRight:"173px",


   // maxHeight: "100vh",
    scrollbarWidth: "none", // Hides scrollbar for Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hides scrollbar for Webkit browsers
    },
    [theme.breakpoints.down("md")]:{
      justifyContent:"center",
      //marginTop:"185px",
     marginLeft:"0px",
     marginRight:"0px",
      padding:"10%"
    },
    [theme.breakpoints.down("sm")]:{
      justifyContent:"center",
      marginTop:"0px",
     marginLeft:"0px",
     marginRight:"0px",
     padding:"10%"
    }
  },
  nav: {
    width: "100%",
  },
  leftCard:{
    width: "100%",
    // height: "92%",

    position: "absolute",
    right: "-15px",
    top: "10%",
    background: 'url("/images/loginCard.png")',

    backgroundSize: "cover",
    backgroundPosition: "bottom",
    //backgroundColor: "#FFFFFFE8",
    boxShadow:
      "-1.84px -1.14px 2.98px 0px #FAFAFA38 inset",
      
  },
  rightCard:{
    width: "100%",
    // height: "90%",
    position: "absolute",
    top: "10%", // Adjust for overlap
    left: "-15px", // Adjust for overlap
    zIndex: 1,
    //background: "linear-gradient(200.74deg, rgba(214, 211, 194, 0.2) 0%, rgba(92, 92, 92, 0.14) 100%)"
    background: 'url("/images/loginCard.png")',

    backgroundSize: "cover",
    backgroundPosition: "bottom",

    backgroundColor: "#FFFFFFE8",
    boxShadow:
      "-1.84px -1.14px 2.98px 0px #FAFAFA38 inset",
      
  },
  cardBox:{
    display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              maxWidth: "227px",
                              [theme.breakpoints.down("md")]:{
                                gap:"10px"
                              }
  }
}));

export default function LoginLayout({ children }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container className={classes.mainBox}>
      <Grid item xs={12} md={6} lg={5} className={classes.leftBox}>
        <Grid
          container
          className={classes.OrangeSection}
          
        >
          <Grid item className={classes.nav}>
            <img
              src="/images/FavMobb.png"
              alt="healthcare_logo"
              className={classes.TopImage}
              onClick={() => history.push("/")}
              style={{
                cursor: "pointer",
                width: "25%",
                height: "100%",
                marginLeft: "-12px",
              }}
            />
 
          </Grid>
          <Grid item>
            <Typography variant="h1">
            Framework and
              <br /> resources to drive your
              <br />
              in-app messaging
            </Typography>
            <Box
              style={{
                width: "230px",
                height: "4px",
                background: "#FFFFFF",
                marginTop: "20px",
              }}
            ></Box>
          </Grid>
          <Grid item container style={{  maxHeight: "483px" }}>
            
            <Box style={{ width: "100%", height: "100%" }}>
              <Box style={{ width: "100%", height: "100%" }}>
                <Grid
                  container
                  style={{
                    padding: "0px",
                    width: "100%",
                    height: "100%",
                    minHeight: "400px",
                  }}
                >
                  <Grid item xs={6}>
                    <Box
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Card
                        className={classes.leftCard}
                      >
                        <Box
                          className="cardContent"
                          style={{ margin: "30px 20px" }}
                        >
                          <Box
                            className={classes.cardBox}
                          >
                            <Box className="imageContainer">
                              <img src="/images/messageNew.svg" />
                            </Box>
                            <Typography variant="h4">
                              Messaging & chat
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 300, maxWidth: "229.25px" }}
                            >
                              Flexible, secure, feature-rich and easy-to-manage
                              messaging for your web and mobile apps.
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ position: "relative" }}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        gap: "15px",
                        left: "36%",
                      }}
                      height="100%"
                    >
                      <Box>
                        <img
                          src="/images/top.svg"
                          style={{ objectFit: "cover" }}
                        />
                      </Box>
                      <Box>
                        <img src="/images/bottom.svg" />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        flexDirection: "column",
                        // position: "absolute",
                        width:"80%"

                        // left: "50%",
                      }}
                      height="100%"
                    >
                      <Box>
                        <img src="/images/voice.svg" />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Card
                        className={classes.rightCard}
                      >
                        <Box
                          className="cardContent"
                          style={{ margin: "30px 20px" }}
                        >
                          <Box
                            className={classes.cardBox}
                          >
                            <Box className="imageContainer">
                              <img src="/images/micO.svg" />
                            </Box>
                            <Typography variant="h4">
                              Voice & video calling
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 300, maxWidth: "229.25px" }}
                            >
                              Enterprise-grade security, robust infrastructure,
                              and a familiar UI from the same vendor as your
                              chat solution.
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}  lg={7} className={classes.rightBox}>
        <Grid item className={classes.children}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
LoginLayout.propTypes = {
  children: PropTypes.node,
};
